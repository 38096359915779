export default {
  error_occurred: 'Beklagar, ett fel uppstod. Försök igen om ett ögonblick.',
  new_content_available: "Det finns en ny sidauppdatering, klicka på 'Uppdatera' för att ladda om sidan.",
  refresh_button_title: 'Uppdatera',
  force_reload_message: 'Ursäkta, ett fel har uppstått. Vårt team arbetar redan med det. Försök att ladda om webbplatsen om ett ögonblick.',
  sign_up: 'Skapa ett konto',
  service: 'Service',
  employee: 'Anställd',
  select_employee: 'Välj en person',
  employees: 'Anställda',
  services: 'Tjänster',
  duration: 'Varaktighet',
  from: 'Från',
  select_date: 'Välj ett datum',
  no_account_question: 'Har du inget konto än?',
  name: 'Förnamn',
  surname: 'Efternamn',
  name_and_surname: 'Fullständiga namn',
  phone: 'Telefon',
  password: 'Lösenord',
  field_is_required: 'Detta fält är obligatoriskt.',
  value_is_too_long: 'Värdet är för långt.',
  value_is_too_short: 'Värdet är för kort.',
  signup_was_successful: 'Registreringen lyckades. Loggar in på panelen.',
  signup_has_failed: 'Registreringen misslyckades. Försök igen om ett ögonblick.',
  email_is_taken: 'Kontot med den angivna e-postadressen finns redan. Om du har glömt ditt lösenord, använd påminnelsealternativet.',
  email_or_password_incorrect: 'E-postadressen eller lösenordet är felaktigt. Om du har glömt ditt lösenord, använd påminnelsealternativet.',
  account_not_found: 'Konto inte funnet.',
  page_not_found: 'hoppsan! Sidan du letade efter kunde inte hittas :(',
  go_back_to_main_page: 'Gå tillbaka till startsidan och försök igen.',
  bookings: 'Bokningar',
  account: 'Mitt konto',
  date: 'Datum',
  cancel: 'Annullera',
  date_from: 'Datum från',
  date_to: 'Dejta till',
  nothing_found_here: 'Ingenting hittades.',
  old_password: 'Gammalt lösenord',
  remove: 'Radera',
  phone_is_invalid: 'Telefonnumret är felaktigt.',
  book: 'Bok',
  sign_up_2: 'Skapa ett konto',
  log_in: 'Logga in',
  your_email_address: 'Din e-postadress',
  select: 'Välj',
  filters: 'Filter',
  expand: 'Bygga ut',
  collapse: 'Kollaps',
  bookings_drawer_title: 'Välj filter för att bara visa de bokningar du letar efter.',
  clear_filters: 'Återställ filter',
  show_more: 'Visa mer',
  dont_you_remember_password: 'Glömt ditt lösenord?',
  password_information: 'Lösenordet ska bestå av minst 8 tecken, innehålla en stor bokstav, en siffra och ett specialtecken.',
  forgotten_password_button: 'Skicka en länk för att ändra ditt lösenord',
  do_you_already_have_an_account: 'Har du redan ett konto?',
  reset_password_title: 'Ställ in nytt lösenord',
  reset_password_subtitle: 'Ange ditt nya lösenord nedan.',
  reset_password_button: 'Spara det nya lösenordet',
  profile: 'Profil',
  logout: 'Logga ut',
  cancel_booking: 'Avboka din bokning',
  status_approved: 'Godkänd',
  status_payment: 'Väntar på betalning',
  status_done: 'Avslutad',
  status_cancelled: 'Inställt',
  cancel_booking_dialog_title: 'Vill du avboka din bokning?',
  cancel_booking_dialog_information: 'Denna åtgärd kan inte ångras!',
  cancel_booking_dialog_button: 'Ja, avboka min bokning',
  my_profile: 'Min profil',
  my_data: 'Mina detaljer',
  upload_new_photo: 'Lägg till ett nytt foto',
  birthday: 'Födelsedag',
  save_changes: 'Spara ändringar',
  phone_number: 'Telefonnummer',
  directional: 'Riktnummer',
  save_new_password: 'Spara det nya lösenordet',
  new_password: 'Nytt lösenord',
  password_change: 'ändra lösenord',
  view: 'Se',
  services_2: 'Tjänster',
  accept_terms_and_conditions_1: 'Genom att klicka på alternativet nedan godkänner jag',
  accept_terms_and_conditions_2: 'Villkor',
  accept_terms_and_conditions_3: 'och jag bekräftar att jag har läst',
  accept_terms_and_conditions_4: 'Sekretesspolicy.',
  to: 'Till',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Logga in',
  email_is_incorrect: 'E-postadressen är felaktig.',
  logged_out: 'Du har loggats ut',
  booking_dialog_date_is_already_taken: 'Den valda tiden är inte tillgänglig. Skulle du ha något emot att välja en annan plats?',
  data_search: 'Söker efter data. Vänta...',
  no_employees: 'Inga anställda',
  no_services: 'Inga tjänster',
  no_categories: 'Inga kategorier',
  no_bookings: 'Hittade inte bokningar.',
  no_subscriptions: 'Inga prenumerationer hittades.',
  female: 'Kvinna',
  male: 'Manlig',
  other: 'Övrig',
  no_numeric_in_password: 'Inget numeriskt tecken i lösenordet.',
  no_lowercase_in_password: 'Ingen gemen bokstav i lösenordet.',
  no_uppercase_in_password: 'Ingen stor bokstav i lösenordet.',
  no_special_character: 'Ingen speciell karaktär.',
  min_n_characters: 'Minsta antal tecken är @{min}.',
  max_n_characters: 'Det maximala antalet tecken är @{max}.',
  email: 'E-post',
  reset_password_fail_text: 'Lösenordet har inte ändrats. Länken har förmodligen gått ut.',
  reset_password_success_title: 'Ditt lösenord har ändrats.',
  reset_password_success_text: 'Du kan logga in med ditt nya lösenord.',
  account_activation: 'Kontoaktivering',
  account_activation_success: 'Kontot har aktiverats.',
  account_activation_fail: 'Det uppstod ett problem när du aktiverade ditt konto. Det ser ut som att kontot redan är aktivt eller att länken inte längre är giltig.',
  account_activation_processing: 'Aktiverar ditt konto. Vänta...',
  account_activation_resend_title: 'Kontot har inte aktiverats',
  account_activation_resend_text: 'Aktivera ditt konto genom att klicka på aktiveringslänken i e-postmeddelandet som vi skickade till dig.',
  account_activation_resend_button: 'Skicka igen',
  confirm_send_activate_link_dialog_subtitle: 'Vi har skickat ett e-postmeddelande med aktiveringslänken till den tidigare angivna e-postadressen.',
  file_is_too_large: 'Den valda filen är för stor.',
  appointment_title: 'Välj en person, datum och tid för bokning',
  others: 'Övrig',
  countryLabel: 'Land',
  requiredMessages: 'Ett telefonnummer krävs',
  date_of_visit: 'Datum',
  hour_of_visit: 'Tid',
  thank_you_for_booking: 'Din bokning',
  no_free_hours: 'Inga tillgängliga tidsluckor på den valda dagen.',
  any_person: 'Slumpmässigt val',
  password_changed: 'Ditt lösenord har ändrats',
  incorrect_old_password: 'Det gamla lösenordet är felaktigt',
  error404: 'Fel 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Förlåt! Den här sidan har tillfälligt inaktiverats.',
  profile_update_success: 'Dina uppgifter har sparats.',
  form_is_invalid: 'Formuläret innehåller fel. Kontrollera de markerade fälten och försök igen.',
  search: 'Sök',
  pay_online_button_title: 'Betala online',
  account_not_activated_info: 'E-postadressen har inte bekräftats. Bekräfta din e-postadress genom att klicka på länken i det skickade meddelandet.',
  paid: 'Betalt',
  payments: 'Betalningar',
  payment_service: 'Service',
  payment_date: 'Datum',
  payment_price: 'Pris',
  payment_success_title: 'Betalningen lyckades, tack!',
  payment_fail_title: 'Betalningen misslyckades, försök igen.',
  pay_again: 'Betala igen',
  newsletter_subscribe_success: 'Tack! Din e-postadress har bekräftats.',
  newsletter_subscribe_fail: 'Det uppstod ett problem när du aktiverade din e-postadress. Det verkar som om e-postadressen redan är aktiv eller att länken inte längre är giltig.',
  newsletter_send_again: 'Skicka igen',
  newsletter_send_again_success: 'Aktiveringslänken har skickats igen.',
  send_again_fail: 'Det uppstod ett problem med att generera en ny aktiveringslänk, din e-postadress kanske redan är aktiv eller så har du precis skapat länken.',
  newsletter_email_is_used: 'E-postadressen finns redan på listan, om adressen inte har bekräftats kan vi skicka om aktiveringslänken.',
  newsletter_unsubscribe_success: 'Tack! Din e-postadress har tagits bort.',
  newsletter_unsubscribe_fail: 'Det gick inte att ta bort e-postadressen. Det ser ut som om e-postadressen redan har tagits bort eller att länken har upphört att gälla.',
  booking_payments: 'Bokningar',
  simple_store_product_transactions: 'Produkter',
  payment_type_select_box: 'Välj typ av betalning',
  payment_description: 'Beskrivning',
  add_to_cart: 'Lägg till i kundvagn',
  remove_from_cart: 'Ta bort från vagnen',
  save: 'Spara',
  bookings_cart_summary: 'Bokningssammanfattning',
  today: 'I dag',
  fill_booking_details_title: 'Ange detaljer',
  create_an_account_question: 'Skapa ett konto och skicka lösenordet till e-postadressen',
  value_is_incorrect: 'Detta värde är inte giltigt.',
  first_available_date: 'Tillgängliga tidsluckor på',
  service_location_1: 'Adress',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Övrig',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Plats',
  booking_not_found: 'Bokningen hittades inte.',
  booking_canceled: 'Bokningen avbröts.',
  booking_canceled_fail: 'Denna bokning kan inte avbokas.',
  day: 'Dag',
  week: 'Vecka',
  month: 'Månad',
  year: 'År',
  booking_plural_1: 'Bokning',
  booking_plural_2: 'Bokningar',
  booking_plural_3: 'Bokningar',
  booking_plural_4: 'Bokningar',
  booking_plural_5: 'Bokningar',
  day_plural_1: 'Dag',
  day_plural_2: 'Dagar',
  day_plural_3: 'Dagar',
  day_plural_4: 'Dagar',
  day_plural_5: 'Dagar',
  week_plural_1: 'Vecka',
  week_plural_2: 'Veckor',
  week_plural_3: 'Veckor',
  week_plural_4: 'Veckor',
  week_plural_5: 'Veckor',
  month_plural_1: 'Månad',
  month_plural_2: 'Månader',
  month_plural_3: 'Månader',
  month_plural_4: 'Månader',
  month_plural_5: 'Månader',
  year_plural_1: 'År',
  year_plural_2: 'År',
  year_plural_3: 'År',
  year_plural_4: 'År',
  year_plural_5: 'År',
  tax: 'Momsregistreringsnummer',
  tax_prefix: 'Prefix',
  get_company_data: 'Sök',
  get_company_data_failed: 'Det gick inte att ladda ner data.',
  company_name: 'Företagsnamn',
  street_and_number: 'gata och nummer',
  postcode: 'Postnummer',
  city: 'Stad',
  country: 'Land',
  subscribe: 'Prenumerera',
  recurrent_fee_info: 'Faktureras varje',
  one_time_fee_info: 'Engångsavgift.',
  new_account_title: 'Skapa ett nytt konto',
  payment_data_title: 'betalningsinformation',
  billing_data_checkbox_question: 'Fakturaspecifikation',
  confirmation_modal_header: 'Åtgärden kräver bekräftelse',
  confirmation_modal_warning: 'Är du säker på att du vill fortsätta?',
  no_way_to_undo_action: 'Denna åtgärd kan inte ångras!',
  delete_payment_method: 'Ta bort kortet',
  add_payment_method: 'Lägg till kort',
  fill_card_details: 'Fyll i betalningsuppgifterna.',
  subscriptions: 'Prenumerationer',
  subscription: 'Prenumeration',
  invoice_number: 'Faktura',
  number: 'siffra',
  status: 'Status',
  details: 'Detaljer',
  created_at: 'Skapad',
  subscription_status_initiated: 'Obetald',
  subscription_status_active: 'Aktiva',
  subscription_status_inactive: 'Inaktiv',
  subscription_status_canceled: 'Inställt',
  ends_at: 'Slutar kl',
  ends_at_default: 'Gäller till slutet av faktureringsperioden',
  select_subscription_to_pay: 'Välj en prenumeration att betala.',
  pay_with_subscription: 'Betala med prenumeration',
  bookings_paid_by_subscription_success_message: 'Betalt',
  bookings_not_paid_by_subscription_error_message: 'Bokningen kunde inte betalas med den valda prenumerationen.',
  stripe: 'Rand',
  provider_tag: 'Metod',
  card: 'Kort',
  cash: 'Kontanter',
  transfer: 'Överföra',
  promo_code_label: 'Rabattkod',
  promo_code_invalid_message: 'Den angivna rabattkoden är ogiltig.',
  payment_method_setup_error: 'Det uppstod ett problem med ditt kort. Din bank har avböjt att auktorisera kortet för senare transaktioner. Kontakta din bank.',
  active_subscription_missing: 'Bokning för denna tjänst kräver ett aktivt abonnemang. Tyvärr har du inget abonnemang att använda.',
  area_code: 'Riktnummer',
  usage: 'Användande',
  create_account_in_stripe_failed: 'Betalningsoperatören har avvisat din prenumerationsdata. Kontrollera att formuläret är korrekt (skattenummer, telefonnummer, e-postadress).',
  additional_auth_required: 'Din bank kräver ytterligare auktorisering',
  general_payment_error: 'Det uppstod ett problem med din betalning. Ditt kort kanske inte stöder prenumerationer, har inköpsgränser eller har inte tillräckligt med pengar för att betala för prenumerationen. Kontakta din bank eller välj en annan betalningsmetod.',
  subscription_set_successfully: 'Prenumerationen är korrekt inställd.',
  booking_created: 'Ny bokning',
  booking_updated: 'Ändring av bokning',
  booking_deleted: 'Radering av en bokning',
  booking_reminder: 'Bokningspåminnelse',
  email_notifications: 'E-postmeddelanden',
  sms_notifications: 'SMS-aviseringar',
  save_user_preferences: 'Spara inställningar',
  free_spots: 'Platser kvar',
  no: 'Nej',
  yes: 'Ja',
  p24_inactive: 'Betaloperatören Przelewy24 kan inte användas. Vänligen kontakta din betalningsoperatör.',
  stripe_customer_invalid: 'Ogiltigt Stripe-kund-id. Betalningsmetoden kan inte kopplas till kunden.',
  stripe_resource_invalid: 'Den valda produkten är inte tillgänglig. Kontrollera produktens tillgänglighet med betalningsoperatören.',
  account_blocked_message: 'Ditt konto är inaktivt. Kontakta administratören.',
  time_zone: 'Tidszon',
  no_spots_left: 'Du har nått det maximala antalet tillgängliga platser.',
  employee_auth_required: 'Logga in på ditt konto eller använd en annan e-postadress. Medarbetarkonton kräver inloggning.',
  subpage_auth_required: 'Logga in på ditt konto för att se fliken.',
  gross: 'Bruttobelopp.',
  subscription_login_info: 'Har du redan ett konto? Logga in nedan för att fylla i dina uppgifter.',
  change_date_time: 'Boka om',
  promo_code_checkbox: 'Jag har en rabattkod',
  booking_promo_code_label: 'Ange rabattkoden',
  select_hour: 'Välj tid',
  id: 'Id',
  booking_rescheduled: 'Din bokning har ändrats.',
  booking_rescheduled_fail: 'Denna bokning kan inte ändras.',
  max_user_bookings_error: 'Du har överskridit det maximala antalet bokningar. Välj en annan tjänst eller kontakta oss.',
  in_total: 'Total',
  company: 'Företag',
  individual: 'Privatperson',
  bulk_payment: 'Massbetalning',
  make_bulk_payment: 'Massbetalning',
  simple_store_product_confirmation_title: 'Fyll i köpformuläret',
  select_service_type: 'Välj ett tjänstealternativ',
  add_to_calendar_button_label: 'Lägg till i kalendern',
  login_to_use_service_with_subscription: 'Logga in på ditt konto för att boka tid för denna tjänst.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Det valda datumet är inte tillgängligt. Välj ett annat ledigt datum. Vid bokning av flera datum, se till att datumen inte utesluter varandra.',
  select_location_calendar_warning: 'Välj en plats för att ladda tillgängliga tider.',
  selectable_location_online_title: 'Online',
  change: 'Ändra',
  show_less_hours_button: 'Visa färre timmar',
  show_more_hours_button: 'Visa fler timmar',
  add_another_button_title: 'Lägg till en till',
  close: 'Stäng',
  selected_time_slots: 'Valda tidsluckor',
  select_location: 'Välj plats'
}
